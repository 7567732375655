import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import OrderStyle from "../../../views/OrderPage/OrderStyle";
import { addTipsAmount } from "../../../store/actions/cart";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress } from "@material-ui/core";
import OrderTotalsItems from "./OrderTotalsItems";
import {
  convertNumerals,
  convertStringNumerals,
  currency_symbol,
  isNegative,
  localeMap,
} from "../../../constants/utils";
import { enUS } from "date-fns/locale";

const useStyles = makeStyles(OrderStyle);
const OrderTotals = ({ orderData, balance_due, setBalanceDue }) => {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const dispatch = useDispatch();
  const { menuData, selected_language } = menuInfo;
  const {
    ui_strings,
    price_decimal_places,
    currency,
    currency_symbol: cSymbol,
    enable_arabic_numerals,
    menu_country,
    taxes: menuTaxes,
    enable_tips,
  } = menuData;
  const {
    tipsAmount: cTipsAmount,
    loadingIikoLoyaltyDiscounts,
    afActiveOrder,
  } = cartInfo;
  const locale = localeMap[selected_language] ?? enUS;

  const {
    created,
    discounts,
    padded_order_id,
    delivery_fees,
    total,
    line_items,
    sub_total,
    taxes,
    order_comments,
    additional_data,
    status,
    payments,
    balance_due: oBalanceDue,
    change,
  } = orderData;

  const { tips_amount, iiko_loyalty_discounts } = additional_data;

  const [freeDelivery, setFreeDelivery] = useState(false);
  const [orderTotal, setOrderTotal] = useState(total);
  const [tipsAmount, setTipsAmount] = useState(cTipsAmount);

  // Total cart items is number of products times the qty
  const totalCartItems = line_items.reduce(
    (prev, item) => (item.status !== "Deleted" ? prev + item.quantity : prev),
    0
  );

  const cartSubTotalStr = ui_strings?.ui_str_cart_subtotal
    ? ui_strings.ui_str_cart_subtotal
        .replace(/items/g, totalCartItems > 1 ? "items" : "item")
        .replace(/%s/g, totalCartItems)
    : `Subtotal (${totalCartItems} items)`;

  const payment_types = {
    cash: ui_strings?.ui_str_cash ?? "Cash",
    machine: ui_strings?.ui_str_machine ?? "Card Machine",
    card: ui_strings?.ui_str_card ?? "Online Payments",
    loyalty: "Loyalty",
    blue_wallet: ui_strings?.ui_str_blue_wallet ?? "Blue Wallet",
  };

  const subTotalStyle = {
    fontSize: 13,
  };

  useEffect(() => {
    if (discounts) {
      const freeDeliveryDiscount = discounts.find(
        (discount) => discount.free_delivery
      );
      setFreeDelivery(!!freeDeliveryDiscount);
    }
  }, [discounts]);

  useEffect(() => {
    if (cTipsAmount === "0.000" && tips_amount) {
      setOrderTotal(Number(total));
      setTipsAmount(tips_amount);
      setBalanceDue(oBalanceDue);
    } else if (cTipsAmount !== "0.000" && tips_amount) {
      setOrderTotal(Number(total) + Number(cTipsAmount) - Number(tips_amount));
      setTipsAmount(cTipsAmount);
      setBalanceDue(oBalanceDue);
    } else {
      setOrderTotal(Number(total) + Number(cTipsAmount));
      setTipsAmount(cTipsAmount);
      setBalanceDue(oBalanceDue + Number(cTipsAmount));
    }
  }, [cTipsAmount, oBalanceDue, setBalanceDue, tips_amount, total]);

  return (
    <>
      <div style={{ fontSize: 12 }}>
        {convertStringNumerals(
          format(new Date(created), "MMMM do, yyyy H:mma", {
            locale,
          }),
          selected_language,
          enable_arabic_numerals
        )}
      </div>
      <div style={{ fontWeight: "bold" }}>
        {ui_strings?.ui_str_order ?? "Order"} #{" "}
        {convertStringNumerals(
          padded_order_id,
          selected_language,
          enable_arabic_numerals
        )}
      </div>
      {["rejected", "canceled"].includes(status) && (
        <div style={{ fontWeight: "bold", color: "red" }}>
          {ui_strings?.ui_str_order_canceled_description ??
            "Order canceled, please contact our staff for assistance"}
        </div>
      )}
      <div style={{ marginTop: 20, width: "100%" }}>
        <table className={classes.lineItemTable}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>
                {ui_strings?.ui_str_qty ?? "Qty"}
              </th>
              <th>{ui_strings?.ui_str_product ?? "Product"}</th>
              <th style={{ textAlign: "right" }}>
                {ui_strings?.ui_str_price ?? "Price"}
                <br />({cSymbol ? cSymbol : currency_symbol(currency)})
              </th>
            </tr>
          </thead>
          <tbody>
            {
              <>
                <OrderTotalsItems line_items={line_items} />
                {sub_total && sub_total > 0 && (
                  <tr style={subTotalStyle}>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      {cartSubTotalStr}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {convertNumerals(
                        parseFloat(sub_total).toFixed(price_decimal_places),
                        selected_language,
                        menu_country,
                        enable_arabic_numerals,
                        price_decimal_places
                      )}
                    </td>
                  </tr>
                )}
                {iiko_loyalty_discounts?.length > 0 &&
                  iiko_loyalty_discounts?.map((discount, index) => (
                    <tr style={subTotalStyle} key={index}>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        {discount?.name}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {isNegative(
                          discount?.discounts.reduce(
                            (prev, d) => prev + d.discountSum,
                            0
                          )
                        )
                          ? ""
                          : "- "}
                        {convertNumerals(
                          Math.abs(
                            discount?.discounts
                              .reduce((prev, d) => prev + d.discountSum, 0)
                              .toFixed(price_decimal_places)
                          ),
                          selected_language,
                          menu_country,
                          enable_arabic_numerals,
                          price_decimal_places
                        )}
                      </td>
                    </tr>
                  ))}
                {taxes &&
                  taxes.length > 0 &&
                  taxes.map((tax, index) => (
                    <tr key={index} style={subTotalStyle}>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        {menuTaxes.find((t) => t.id === tax.id)?.name ??
                          tax.name}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {convertNumerals(
                          parseFloat(tax.amount).toFixed(price_decimal_places),
                          selected_language,
                          menu_country,
                          enable_arabic_numerals,
                          price_decimal_places
                        )}
                      </td>
                    </tr>
                  ))}
                {delivery_fees && delivery_fees > 0 && (
                  <tr style={subTotalStyle}>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      Delivery Fees
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        textDecoration: freeDelivery ? "line-through" : "none",
                      }}
                    >
                      {convertNumerals(
                        parseFloat(delivery_fees).toFixed(price_decimal_places),
                        selected_language,
                        menu_country,
                        enable_arabic_numerals,
                        price_decimal_places
                      )}
                    </td>
                  </tr>
                )}
                {discounts?.length > 0 &&
                  discounts.map((discount, index) => (
                    <tr style={subTotalStyle} key={index}>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        Discount ({discount?.name})
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {"- "}
                        {convertNumerals(
                          parseFloat(discount?.amount_value).toFixed(
                            price_decimal_places
                          ),
                          selected_language,
                          menu_country,
                          enable_arabic_numerals,
                          price_decimal_places
                        )}
                      </td>
                    </tr>
                  ))}
                {enable_tips && (
                  <tr style={subTotalStyle}>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      {parseFloat(tipsAmount) > 0 &&
                        payments.length === 0 &&
                        !!afActiveOrder && (
                          <IconButton
                            style={{
                              color: "red",
                              marginTop: -4,
                            }}
                            className={classes.itemDeleteBtn}
                            color="inherit"
                            aria-label="delete"
                            disabled={loadingIikoLoyaltyDiscounts}
                            onClick={() => dispatch(addTipsAmount("0.000"))}
                          >
                            <DeleteIcon fontSize={"small"} />
                          </IconButton>
                        )}
                      {ui_strings?.ui_str_tips ?? "Tips"}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {loadingIikoLoyaltyDiscounts ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        <>
                          {convertNumerals(
                            parseFloat(tipsAmount).toFixed(
                              price_decimal_places
                            ),
                            selected_language,
                            menu_country,
                            enable_arabic_numerals,
                            price_decimal_places
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                )}
                <tr style={{ ...subTotalStyle, fontWeight: "bold" }}>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {ui_strings?.ui_str_cart_total ?? "Total"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {convertNumerals(
                      parseFloat(String(Number(orderTotal))).toFixed(
                        price_decimal_places
                      ),
                      selected_language,
                      menu_country,
                      enable_arabic_numerals,
                      price_decimal_places
                    )}
                  </td>
                </tr>

                {payments?.length > 0 && (
                  <>
                    {payments.map((payment, index) => (
                      <tr key={index} style={subTotalStyle}>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          {payment_types[payment.payment_type] ||
                            payment.payment_type}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {"- "}
                          {convertNumerals(
                            parseFloat(String(Number(payment.amount))).toFixed(
                              price_decimal_places
                            ),
                            selected_language,
                            menu_country,
                            enable_arabic_numerals,
                            price_decimal_places
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr style={{ ...subTotalStyle, fontWeight: "bold" }}>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        {ui_strings?.ui_str_payment_balance_due ??
                          "Balance Due"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {convertNumerals(
                          parseFloat(String(Number(balance_due))).toFixed(
                            price_decimal_places
                          ),
                          selected_language,
                          menu_country,
                          enable_arabic_numerals,
                          price_decimal_places
                        )}
                      </td>
                    </tr>
                    {change > 0 && (
                      <tr style={{ ...subTotalStyle }}>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          {ui_strings?.ui_str_payment_change ?? "Change"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {convertNumerals(
                            parseFloat(String(Number(change))).toFixed(
                              price_decimal_places
                            ),
                            selected_language,
                            menu_country,
                            enable_arabic_numerals,
                            price_decimal_places
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                )}

                {order_comments ? (
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <td colSpan={3}>
                      {ui_strings?.ui_str_order_comments ?? "Order Comments"}:{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          fontStyle: "italic",
                        }}
                      >
                        {order_comments}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </>
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrderTotals;
